/*-----------------css cards view------------------ */

.wrapper {
    padding-top: 90px;
    padding-bottom: 20px;
    margin: 0 auto;
    max-width: 70%;
    justify-content: center;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 15px;
}

.item1 {
    background: hsla(120, 100%, 97%, 0.50);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    grid-column: 1/3;
}

.item2 {
    background: hsla(72, 74%, 51%, 0.50);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    grid-column: 3/5;
    grid-row: 1/3;
}

.item3 {
    background: rgba(173, 34, 120, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    grid-column: 1/3;
    grid-row: 2/3;
}

.item4 {
    background:rgba(7, 218, 255, 0.5);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    grid-column: 1/5;
    grid-row: 3/5;
}


/*------------------------css landing-------------------*/

.landing {
    height: auto;
    position: fixed;
    z-index: -2;
}

.fig-1 {
    position: absolute;
    width: 0px;
    height: 0px;
    z-index: -2;
    border-top: 35vh solid #ffe186;
    border-right: 80vw solid transparent;
}

.fig-2 {
    position: absolute;
    width: 0px;
    height: 0px;
    z-index: -2;
    border-top: 30vh solid #FFC107;
    border-right: 50vw solid transparent;
}


    /* position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 75vw;
    height: 50vh;
    background: #2c3e50;
    z-index: -2;
    transform: skewY(30deg);
    transform-origin: top right;


/* -----------------------NAVBAR------------------------------ */

.logo-white {
    width: 80px;
    height: auto;
    cursor: pointer;
}

.photo-login {
    all: unset;
}

.photo {
    width: 40px;
    border-radius: 50%;
}

.nav-bar-all {
    background: #000000;
    position: fixed;
    z-index: 3;
    width: 100vw;
}

/* .nav-bar-all .nav-logo{
    border: blanchedalmond solid 1px;
} */

.nav-bar-all .nav-bar {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.nav-bar-all .nav-login {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

/*--------------------Lugares------------------------------*/

.container-cards{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding-top: 100px;
}
.card-place{
    display: flex;
    align-content: center;
}

.card-place .cards{
    margin: 10px;
    width: 20rem;
    display: flex;
    justify-content: center;
}

/*--------------------Foro------------------------------*/

.container-foro{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 30px;
}

.foro-cards{
    margin: 30px;
}

/* -------------------------Footer-------------------------------- */

.footer {
    background: #000;
    height: 20vh;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    z-index: 3;
}

.footer .social {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.footer .siguenos {
    width: 30%;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: space-between;
}

.footer .siguenos .title-sig {
    display: flex;
    justify-content: center;
}

.footer .siguenos .title-sig p {
    color: #fff;
}

.footer .siguenos .icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer .siguenos .icons-social {
    color: #FFC107;
    height: 30px;
    width: 30px;
}

.footer .contactanos {
    width: 30%;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: space-evenly;
}

.footer .contactanos .title-con {
    display: flex;
    justify-content: center;
}

.footer .contactanos .title-con p {
    color: #fff;
}

.footer .contactanos .formulario {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.footer .contactanos .formulario .input-email {
    width: 80%;
}

.footer .derechos {
    display: flex;
    height: 10px;
    width: 100%;
    color: rgb(184, 184, 184);
    justify-content: center;
    align-items: flex-end;
}

/*-------------------MAP----------------*/

.container-map {
    width: 80vw;
    height: 100vh;
    padding-top: 100px;
    margin: auto;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
}

.container-map .title-map {
    text-align: center;
}

.container-map .map {
    z-index: 1;
    margin: auto;
    width: 100%;
    height: 80%;
}

.map-target {
    width: 20vw;
    height: 55vh;
    //padding-top: 100px;
    //margin: auto;
    //display: flex;
    //flex-flow: column wrap;
    //align-content: center;
}


.map-target .title-map {
    text-align: center;
}
.map-target .map {
    z-index: 1;
    margin: auto;
    width: 100%;
    height: 80%;
}

/*--------------------------------------------------------------------------*/

/*------------------PLACES-----------------*/

.places {
    margin-top: 100px;
}

@media screen and (max-width: 720px) {
    .nav-bar-all .nav-login {
        justify-content: flex-end;
        width: 100%;
    }

    .wrapper {
        max-width: 100%;
    }
}

@media screen and (max-width: 960px) {}
